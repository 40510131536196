button,
.button {
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Open Sans', sans-serif;
	font-size: 1.125rem;
	font-weight: 600;
	outline: none;

	min-height: var(--button-height);
	max-width: var(--button-max-width);
	box-sizing: border-box;

	padding: var(--half-gap);
	margin: 0;

	border-radius: var(--button-border-radius);

	background: var(--theme-background);
	color: var(--theme-secondary);

	border: none;

	cursor: default;

	text-decoration: none;
}

@media (pointer: fine), (pointer: none) {
	button,
	.button {
		cursor: pointer;
	}
}

button.primary,
.button.primary {
	background: var(--button-primary-background);
	color: var(--text-on-secondary-color);
}

button.primary:disabled,
.button.primary.disabled {
	background: var(--button-primary-disabled-background);
}

@media (pointer: fine), (pointer: none) {
	button.primary:not(:disabled):hover,
	.button.primary:not(.disabled):hover {
		background: var(--theme-secondary);
	}
}

button:disabled,
.button.disabled {
	cursor: default;
}

button.secondary,
.button.secondary {
	border: var(--button-border-width) solid var(--theme-secondary);
}

button.secondary:disabled,
.button.secondary.disabled {
	border: var(--button-border-width) solid var(--theme-secondary-muted);
	color: var(--theme-secondary-muted);
}

@media (pointer: fine), (pointer: none) {
	button.secondary:not(:disabled):hover,
	.button.secondary:not(.disabled):hover {
		background: var(--theme-secondary);
		color: var(--text-on-secondary-color);
	}
}

button.secondary:not(:disabled):active,
.button.secondary:not(.disabled):active {
	border: var(--button-border-width) solid var(--button-active-background);
}

button.text-only,
.button.text-only {
	min-height: var(--button-small-height);
	width: auto;
	max-width: none;
	padding: var(--half-gap) 20px;

	position: relative;
	z-index: 1;
}

button.text-only > .fa-icon,
.button.text-only > .fa-icon {
	height: 0.8em;
	margin-right: 3px;
}

button.text-only:disabled,
.button.text-only.disabled,
button.text-only-small:disabled,
.button.text-only-small.disabled {
	color: var(--theme-secondary-muted);
}

button.text-only-small,
.button.text-only-small {
	height: var(--button-small-height);
	min-height: var(--button-small-height);
	color: var(--text-on-background-color);
	font-size: 0.875rem;
	width: auto;
	max-width: none;
	padding: 0 10px;

	position: relative;
	z-index: 1;
}

button.text-only:after,
.button.text-only:after,
button.text-only-small:after,
.button.text-only-small:after {
	content: "";

	position: absolute;

	top: 50%;
	left: 50%;

	width: 0;
	height: 0;

	border-radius: var(--button-border-radius);
	z-index: -1;
}

@media (pointer: fine), (pointer: none) {
	button.text-only:not(:disabled):not(:active):hover:after,
	.button.text-only:not(.disabled):not(:active):hover:after,
	button.text-only-small:not(:disabled):not(:active):hover:after,
	.button.text-only-small:not(.disabled):not(:active):hover:after {
		background: var(--button-small-hover-background);

		transition: top var(--button-hover-animation), left var(--button-hover-animation), width var(--button-hover-animation), height var(--button-hover-animation);

		left: 0;
		top: 0;

		height: 100%;
		width: 100%;
	}
}

button.primary:not(:disabled):active,
.button.primary:not(.disabled):active,
button.secondary:not(:disabled):active,
.button.secondary:not(.disabled):active,
button.text-only:not(:disabled):active,
.button.text-only:not(.disabled):active,
button.text-only-small:not(:disabled):active,
.button.text-only-small:not(.disabled):active {
	background: var(--button-active-background);
	color: var(--text-on-secondary-color);
}

.icon-only-button {
	background: none;
	outline: none;

	border: none;
	border-radius: 100%;

	font-size: 1em;
	font-weight: normal;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 2em;
	min-height: 2em;

	color: var(--text-on-background-color);

	position: relative;
	z-index: 0;
}

.icon-only-button:before {
	content: "";

	width: 0;
	height: 0;

	border-radius: 100%;

	position: absolute;
	z-index: -1;
}

@media (pointer: fine), (pointer: none) {
	.icon-only-button:hover {
		color: var(--theme-secondary);
	}

	.icon-only-button:hover:not(:active):before {
		background: var(--button-small-hover-background);

		transition: width var(--button-hover-animation), height var(--button-hover-animation);

		width: 2em;
		height: 2em;
	}
}

.icon-only-button:active {
	background: var(--text-on-background-color);
	color: var(--theme-background);
}
