
.styled {
	display: flex;
	justify-content: center;
}

.styled input:not([disabled]) ~ .text,
.styled input:not([disabled]) ~ .box,
.styled input:not([disabled]) ~ svg {
	cursor: pointer;
}

.styled.checkbox input[type="checkbox"] ~ .text {
	padding-left: var(--gap);
}

.styled.checkbox input[type="checkbox"] {
	display: none;
}

.styled.checkbox input[type="checkbox"] + .box {
	display: flex;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border: 2px solid var(--checkbox-border);
	border-radius: 5px;
	padding: 2px;
	color: var(--checkbox-border);
}

.styled.checkbox input[type="checkbox"]:disabled + .box {
	border-color: var(--checkbox-border-disabled);
}

.styled.checkbox input[type="checkbox"]:disabled + .box svg {
	color: gray;
}

.styled.checkbox input[type="checkbox"]:not(:checked) + .box svg {
	visibility: hidden;
}

.styled.checkbox input[type="checkbox"]:not(:disabled):checked + .box svg {
	visibility: visible;
}

.styled.toggle input[type="checkbox"] ~ .text {
	padding-right: var(--half-gap);
}

.styled.toggle input[type="checkbox"] {
	display: none;
}

.styled.toggle input[type="checkbox"] ~ svg, .styled.toggle input[type="checkbox"] ~ .text {
	color: var(--checkbox-border);
}

.styled.toggle input[type="checkbox"] ~ svg {
	align-self: center;
	order: 1;
}

.styled.toggle input[type="checkbox"]:checked + svg {
	transform: rotate(180deg);
}
