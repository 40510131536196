@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-display: swap;
	font-weight: 400;
	src: url('@fontsource/open-sans/files/open-sans-latin-400-normal.woff2') format('woff2'), url('@fontsource/open-sans/files/open-sans-latin-400-normal.woff') format('woff');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-display: swap;
	font-weight: 600;
	src: url('@fontsource/open-sans/files/open-sans-latin-600-normal.woff2') format('woff2'), url('@fontsource/open-sans/files/open-sans-latin-600-normal.woff') format('woff');
}
