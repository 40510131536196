@import url("./fonts.css");

:root {
	--theme-background: #FFFFFF;
	--theme-background-muted: #EEEEEE;
	--theme-primary: #FF8800;
	--theme-secondary: #0077BB;
	--theme-secondary-muted: #AAAAAA;
	--theme-negative: #DE4F22;
	--theme-positive: #70E770;
	--theme-backdrop: rgba(0, 0, 0, 0.3);

	--text-on-background-color: #333;
	--text-muted-on-muted-color: #999;
	--text-muted-on-background-color: #555;
	--text-negative-on-background-color: var(--theme-negative);
	--text-on-primary-color: #FFFFFF;
	--text-on-secondary-color: #FFFFFF;
	--spacer-color: #ddd;

	--content-limit-width: 420px;

	--border-on-background-color: #aaa;

	--button-border-radius: 27px;
	--button-border-width: 2px;
	--button-height: 56px;
	--button-small-height: 40px;
	--button-max-width: 255px;

	--button-primary-background: transparent linear-gradient(180deg, var(--theme-secondary) 0%, #003D8F 100%);
	--button-primary-disabled-background: transparent linear-gradient(180deg, #C8C8C8 0%, var(--theme-secondary-muted) 100%);
	--button-small-hover-background: #EDEDEC;
	--button-active-background: #333;
	--button-hover-animation:  0.3s ease-out;

	--toast-positive-text-color: #fff;
	--toast-positive-background-color: #333;
	--toast-negative-text-color: #fff;
	--toast-negative-background-color: var(--theme-negative);

	--list-background: #DCEFFA;

	--checkbox-border-disabled: #aaa;
	--checkbox-border: #07b;

	--gap: 10px;
	--half-gap: calc(0.5 * var(--gap));

	--box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);

	--header-color: #333;
	--header-height: 70px;

	--mobile-min-height: 100vh;
}

html {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;

	height: 100%;
}

body {
	color: var(--text-on-background-color);
	margin: 0;

	user-select: none;
}

p {
	font-size: 1rem;
	margin: 0;
}

a {
	text-decoration: none;
	color: var(--theme-secondary);
}

.content-width-limit {
	max-width: var(--content-limit-width);
}

.content-wrapper {
	--content-padding-top: calc(2 * var(--gap));
	--content-padding-bottom: calc(3 * var(--gap));
	display: grid;
	padding: var(--content-padding-top) var(--gap) var(--content-padding-bottom);
	min-height: 0;
}

main:not(.media-desktop) .content-height-limit-mobile {
	max-height: calc(var(--mobile-min-height) - var(--content-padding-top) - var(--content-padding-bottom) - var(--header-height));
	min-height: 380px;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.text-bold {
	font-weight: bold;
}

.text-smaller {
	font-size: 0.9rem;
}
.text-smallest {
	font-size: 0.7rem;
}

.text-large {
	font-size: 4rem;
}

.text-larger {
	font-size: 1.1rem;
}

.text-muted {
	color: var(--text-muted-on-background-color);
}

.text-muted-on-muted {
	color: var(--text-muted-on-muted-color);
}

.text-negative {
	color: var(--text-negative-on-background-color);
}

.text-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.text-selectable {
	user-select: text;
}

.text-wide-lines {
	line-height: 1.8;
}

.teaser {
	margin: 0 auto var(--half-gap) auto;
}

.caption {
	margin: 0 auto calc(2 * var(--gap)) auto;
}

.visual-container {
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	max-width: var(--content-limit-width);
	margin: 0 auto;
}

.visual-container > img {
	flex: 1 1 150px;
	min-height: 0;
	max-width: 80%;
	object-fit: contain;
}

.overlayed {
    overflow: hidden;
}
