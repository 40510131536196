file-list {
	display: block;
	overflow-y: auto;
}

file-list-item {
	display: flex;

	border-radius: 4px;
	background: var(--list-background);

	padding: var(--gap);
	margin-bottom: var(--half-gap);

	align-items: center;
	min-height: 40px;
}

file-list-icon {
	flex: 0 0 auto;

	font-size: 2rem;
	margin-right: var(--gap);
	display: flex;
}

file-list-caption {
	flex: 1 1 0;
	margin-right: var(--gap);
	min-width: 0;

	font-weight: bold;
	line-height: 22px;
}

file-list-action .icon-only-button {
	font-size: 1.325em;
}

@media (pointer: fine), (pointer: none) {
	file-list-action .icon-only-button:hover:not(:active):before {
		background: var(--theme-background);
	}
}
